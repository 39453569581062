import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/twocolumn.module.css";

export function TwoColumn({ children, linkLeft, linkRight }) {
  let link = undefined;
  if (linkLeft === undefined) {
    if (linkRight !== undefined) {
      link = linkRight.href;
    }
  } else {
    if (linkRight === undefined) {
      link = linkLeft.href;
    }
  }
  return (
    <article className={styles.container}>
      {link && (
        <Link href={link}>
          <a className={styles.link} />
        </Link>
      )}
      <div className={styles.wrapper}>{children}</div>
      {linkLeft && (
        <div className={styles.left}>
          <Link href={linkLeft.href}>
            <a className={styles.link}> {linkLeft.text} </a>
          </Link>
        </div>
      )}
      {linkRight && (
        <div className={styles.right}>
          <Link href={linkRight.href}>
            <a className={styles.link}> {linkRight.text} </a>
          </Link>
        </div>
      )}
    </article>
  );
}

TwoColumn.propTypes = {};
