import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { resolveSections } from "./lib/resolve-sections";
import styles from "./styles/render.module.css";

function resolveError(msg) {
  return (
    <div className={styles.error}>
      <h1>{msg}</h1>
    </div>
  );
}

export function Render(props) {
  const { sections, disclaimer, allProducts } = props;

  if (!sections) {
    return resolveError("Missing sections");
  }

  return (
    <>
      {sections.map((section, index) => {
        const SectionComponent = resolveSections(section);

        if (!SectionComponent) {
          return resolveError(`Missing section ${section._type}`);
        }
        if (index === 0) {
          return (
            <div className={styles.spacer} key={section._key}>
              <SectionComponent
                {...section}
                allProducts={allProducts}
                disclaimer={disclaimer}
                priority={true}
              />
            </div>
          );
        }
        return (
          <LazyLoad offset={500} height={500} key={section._key}>
            <SectionComponent
              {...section}
              allProducts={allProducts}
              disclaimer={disclaimer}
              priority={false}
            />
          </LazyLoad>
        );
      })}
    </>
  );
}

Render.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      _type: PropTypes.string,
      _key: PropTypes.string,
      section: PropTypes.instanceOf(PropTypes.object),
    })
  ),
};
