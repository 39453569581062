import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/singleimage.module.css";

export function SingleImage({ title, link, children }) {
  return (
    <article className={styles.container}>
      <Link href={link.href}>
        <a className={styles.link} />
      </Link>
      {/* Background */}
      <div className={styles.background}>
        <div className={styles.backgroundImage}>{children}</div>
      </div>
      {/* Foreground */}
      <div className={styles.foreground}>
        <h1 className={styles.title}>{title}</h1>
        <Link href={link}>
          <a className={styles.link}>{link.text}</a>
        </Link>
      </div>
    </article>
  );
}

SingleImage.propTypes = {};
