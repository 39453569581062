import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/picture.module.css";

export function PictureInPicture({ imgBackground, imgForeground, title, link }) {
  return (
    <article className={styles.container}>
      <Link href={link.href}>
        <a className={styles.link} />
      </Link>
      <div className={styles.background}>
        <div className={styles.imageBack}>{imgBackground}</div>

        <div className={styles.imageFront}>{imgForeground}</div>
      </div>
      <div className={styles.foreground}>
        <h1 className={styles.title}>{title}</h1>
        <Link href={link.href}>
          <a className={styles.link}>{link.text}</a>
        </Link>
      </div>
    </article>
  );
}

PictureInPicture.propTypes = {};
