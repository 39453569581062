import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/splitSingleImage.module.css";

export function SplitSingleImage({ children, link, title }) {
  return (
    <div className={styles.container}>
      <Link href={link.href}>
        <a className={styles.link} />
      </Link>
      <div className={styles.left}>
        {children}
        <div className={styles.right}>
          <h1 className={styles.title}>{title}</h1>

          <Link href={link.href}>
            <a>{link.text}</a>
          </Link>
        </div>
      </div>
    </div>
  );
}

SplitSingleImage.propTypes = {};
