import PropTypes from "prop-types";
import Link from "next/link";
import styles from "./styles/splitThreeImage.module.css";

export function SplitThreeImage({ imgLeft, imgCenter, imgRight, title, link }) {
  return (
    <article className={styles.container}>
      <Link href={link.href}>
        <a className={styles.link} />
      </Link>
      <div className={styles.background}>
        <div className={styles.left}>{imgLeft}</div>
        <div className={styles.middle}>{imgCenter}</div>
        <div className={styles.right}>{imgRight}</div>
      </div>
      <div className={styles.foreground}>
        <h1 className={styles.title}>{title}</h1>

        <Link href={link.href}>
          <a>{link.text}</a>
        </Link>
      </div>
    </article>
  );
}

SplitThreeImage.propTypes = {};
